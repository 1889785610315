import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import CareerAccordion from "../components/CareerAccordion"
import { FaExclamation } from "react-icons/fa"

export default function Careers() {
  return (
    <Layout>
      <Seo
        title="Careers"
        description="Current vacancies available at CAST Rope Access."
        pathname="/careers"
      />
      <section class="hero hero--sheq px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../images/hero-careers.jpg"
          alt="Careers"
          placeholder="blurred"
        />
        <div class="container mx-auto relative z-10">
          <div class="hero__content text-center">
            <h1 class="text-white pb-4 relative">
              Careers
              <span className="border-b-2 border-brand-orange inline-block h-1 w-24 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
            </h1>
          </div>
        </div>
      </section>
      <section className="px-4 pt-24 pb-12">
        <div className="container mx-auto">
          {/*<h2 className="text-center text-brand-blue mb-12">
            Current Positions Available
          </h2>

          <p className="text-center">
            There are currently no positions available.
          </p>*/}

          <div className="px-2">
            <div className="md:flex md:flex-wrap -mx-2">
              <div className="career md:w-1/2 md:px-2">
                <div className="bg-brand-blue rounded-xl mb-8 shadow-xl">
                  <StaticImage
                    className="rounded-t-xl"
                    src="../images/career-ndt-inspector.jpg"
                    alt="Technical Sales Representative"
                    placeholder="blurred"
                  />
                  <h4 className="bg-brand-orange text-white py-4 px-6 mx-8 uppercase font-semibold tracking-wider text-center rounded-lg mb-12 -mt-9 relative z-10">
                    Technical Sales Representative
                  </h4>

                  <div className="bg-white text-sm text-brand-blue font-semibold pt-12 pb-4 px-4 rounded-lg mx-4 mb-8 relative">
                    <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-white w-16 h-16 p-4 rounded-full shadow-lg">
                      <StaticImage
                        className="w-8 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
                        src="../images/career-attributes.png"
                        alt="Career Attributes"
                        placeholder="blurred"
                      />
                    </div>
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-1/2 px-2">
                        <ul className="list-disc ml-6">
                          <li>Solution driven</li>
                          <li>Proactive</li>
                          <li>Team Player</li>
                          <li>Good communication</li>
                        </ul>
                      </div>

                      <div className="w-1/2 px-2">
                        <ul className="list-disc ml-6">
                          <li>Conscientious</li>
                          <li>Innovative thinker</li>
                          <li>Diploamatic</li>
                          <li>Conflict resolution skills</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="bg-brand-orange pt-8 px-4 pb-4">
                    <CareerAccordion title="Job Description">
                      <p>
                        The Technical Sales Representative role is to market to
                        CAST’s clients our full service offering, to generate
                        new business and to manage the client portfolio. The
                        candidate must demonstrate visible leadership and
                        commitment to the company values, including HSE and
                        quality performance.
                      </p>
                    </CareerAccordion>

                    <CareerAccordion
                      title="Technical Sales Representative
Duties:"
                    >
                      <ul className="list-disc ml-6">
                        <li>Provide a positive working environment.</li>
                        <li>
                          Maintain, encourage and possess "ownership mentality."
                        </li>
                        <li>
                          Ensure that you are familiar with all company and
                          client health and safety standards.
                        </li>
                        <li>Comply with ASP QA Manager requirements.</li>
                        <li>
                          Compile proposals and reports related to technical
                          products and services.
                        </li>
                        <li>
                          Maintain an acceptable level of physical fitness.
                        </li>
                      </ul>
                    </CareerAccordion>

                    <CareerAccordion
                      title="Technical Sales Representative
Requirements:"
                    >
                      <ul className="list-disc ml-6">
                        <li>
                          A minimum of 2 years of sales or marketing experience.
                        </li>
                        <li>
                          Technical knowledge and a comprehensive understanding
                          of how the company services work.
                        </li>
                        <li>Strong interpersonal and communication skills.</li>
                        <li>The ability to identify and follow up on leads.</li>
                        <li>
                          Exceptional skills in selling products and closing
                          deals.
                        </li>
                        <li>Knowledge of sales promotion techniques.</li>
                        <li>
                          Excellent presentation skills and a professional
                          appearance.
                        </li>
                        <li>
                          A valid driver’s license and a willingness to travel
                          extensively locally(RSA).
                        </li>
                      </ul>
                    </CareerAccordion>
                  </div>

                  <div className="text-center px-4 py-8">
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Position Location:
                    </h5>
                    <p className="text-white mb-4 text-sm">
                      Umbilo, Durban, South Africa.
                    </p>
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Send Your CV To:
                    </h5>
                    <p className="text-white mb-4 text-sm">
                      <a href="mailto:hr@aspropeaccess.com">
                        {" "}
                        hr@aspropeaccess.com{" "}
                      </a>
                    </p>
                    {/* <div className="inline-block w-12 h-12 bg-white rounded-full shadow-lg relative">
                      <FaExclamation className="text-2xl text-brand-blue absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2" />
                    </div>
                    <p className="text-sm font-bold text-white">
                      Applications Close 15 September 2022
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="career md:w-1/2 md:px-2">
                <div className="bg-brand-blue rounded-xl mb-8 shadow-xl">
                  <StaticImage
                    className="rounded-t-xl"
                    src="../images/career-ndt-inspector.jpg"
                    alt="Rope Access Boilermakers"
                    placeholder="blurred"
                  />
                  <h4 className="bg-brand-orange text-white py-4 px-6 mx-8 uppercase font-semibold tracking-wider text-center rounded-lg mb-6 -mt-9 relative z-10">
                    Rope Access Boilermakers
                  </h4>

                  <div className="bg-brand-orange pt-8 px-4 pb-4">
                    <CareerAccordion title="Requirements">
                      <ul className="list-disc ml-6">
                        <li>Red Seal Certification required</li>
                        <li>
                          IRATA L1 minimum, with at least 500 hours experience
                        </li>
                        <li>Must reside within South African borders</li>
                      </ul>
                    </CareerAccordion>
                  </div>

                  <div className="text-center px-4 py-8">
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Position Location:
                    </h5>
                    <p className="text-white mb-4 text-sm">
                      Local project, within coastal region of South Africa
                    </p>
                    <p className="text-white mb-4 text-sm">2-3 Week Duration</p>
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Send Your CV To:
                    </h5>
                    <p className="text-white mb-4 text-sm">
                      <a href="mailto:hr@castropeaccess.com">
                        {" "}
                        hr@castropeaccess.com{" "}
                      </a>
                    </p>
                    <div className="inline-block w-12 h-12 bg-white rounded-full shadow-lg relative">
                      <FaExclamation className="text-2xl text-brand-blue absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2" />
                    </div>
                    <p className="text-sm font-bold text-white">
                      By submitting your CV for this position, you hereby agree
                      that CAST Rope Access may advise clients of your
                      availability and skills for potential work. No personal
                      information shall be shared, in accordance with the legal
                      requirements.
                    </p>
                  </div>
                </div>
              </div>

              <div className="career md:w-1/2 md:px-2">
                <div className="bg-brand-blue rounded-xl mb-8 shadow-xl">
                  <StaticImage
                    className="rounded-t-xl"
                    src="../images/career-ndt-inspector.jpg"
                    alt="Rope Access Welders"
                    placeholder="blurred"
                  />
                  <h4 className="bg-brand-orange text-white py-4 px-6 mx-8 uppercase font-semibold tracking-wider text-center rounded-lg mb-6 -mt-9 relative z-10">
                    Rope Access Welders
                  </h4>

                  <div className="bg-brand-orange pt-8 px-4 pb-4">
                    <CareerAccordion title="Requirements">
                      <ul className="list-disc ml-6">
                        <li>AWS D1.1, DNV or ABS certified</li>
                        <li>
                          IRATA L1 minimum, with at least 500 hours experience
                        </li>
                        <li>Must reside within South African borders</li>
                      </ul>
                    </CareerAccordion>
                  </div>

                  <div className="text-center px-4 py-8">
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Position Location:
                    </h5>
                    <p className="text-white mb-4 text-sm">
                      Local project, within coastal region of South Africa
                    </p>
                    <p className="text-white mb-4 text-sm">2-3 Week Duration</p>
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Send Your CV To:
                    </h5>
                    <p className="text-white mb-4 text-sm">
                      <a href="mailto:hr@castropeaccess.com">
                        {" "}
                        hr@castropeaccess.com{" "}
                      </a>
                    </p>
                    <div className="inline-block w-12 h-12 bg-white rounded-full shadow-lg relative">
                      <FaExclamation className="text-2xl text-brand-blue absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2" />
                    </div>
                    <p className="text-sm font-bold text-white">
                      By submitting your CV for this position, you hereby agree
                      that CAST Rope Access may advise clients of your
                      availability and skills for potential work. No personal
                      information shall be shared, in accordance with the legal
                      requirements.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="career md:w-1/2 md:px-2">
                <div className="bg-brand-blue rounded-xl mb-8 shadow-xl">
                  <StaticImage
                    className="rounded-t-xl"
                    src="../images/career-financial-manager.jpg"
                    alt="Financial Manager"
                    placeholder="blurred"
                  />
                  <h4 className="bg-brand-orange text-white py-4 px-6 mx-8 uppercase font-semibold tracking-wider text-center rounded-lg mb-12 -mt-9 relative z-10">
                    Financial Manager
                  </h4>

                  <div className="bg-white text-sm text-brand-blue font-semibold pt-12 pb-4 px-4 rounded-lg mx-4 mb-8 relative">
                    <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-white w-16 h-16 p-4 rounded-full shadow-lg">
                      <StaticImage
                        className="w-8 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
                        src="../images/career-attributes.png"
                        alt="Career Attributes"
                        placeholder="blurred"
                      />
                    </div>
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-1/2 px-2">
                        <ul className="list-disc ml-6">
                          <li>Solution driven</li>
                          <li>Proactive</li>
                          <li>Integrous</li>
                          <li>Good communication</li>
                        </ul>
                      </div>

                      <div className="w-1/2 px-2">
                        <ul className="list-disc ml-6">
                          <li>Conscientious</li>
                          <li>Innovative thinker</li>
                          <li>Diplomatic</li>
                          <li>Conflict resolution skills</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="bg-brand-orange pt-8 px-4 pb-4">
                    <CareerAccordion title="Job Description">
                      <p className="text-sm text-white">
                        The Financial Manager will need to effectively lead a
                        productive and dynamic finance team, be responsible for
                        all general and statutory accounting matters, ensure the
                        business is kept in tax good standing and oversee the
                        payroll function.
                      </p>
                    </CareerAccordion>

                    <CareerAccordion title="Minimum Requirements">
                      <ul className="list-disc ml-6">
                        <li>
                          At least 7 years’ financial management experience.
                        </li>
                        <li>
                          Foreign exchange and multiple currencies exposure.
                        </li>
                        <li>SARS and tax audits exposure.</li>
                        <li>At least 7 years’ people management.</li>
                        <li>
                          Pastel, Xero and SAP experience Advanced Microsoft
                          excel skills.
                        </li>
                      </ul>
                    </CareerAccordion>

                    <CareerAccordion title="Job Purpose">
                      <ul className="list-disc ml-6">
                        <li>
                          Effective management of the finance and administration
                          functions including all statutory and tax requirements
                          for all of the companies in the ASP group.
                        </li>
                        <li>
                          Overall responsibility for control of all financial
                          transactions and accounting matters, including
                          internal and external audit require- ments.
                        </li>
                        <li>
                          This is to service our local clients in and around
                          Durban however the candidate may be required to work
                          nationally.
                        </li>
                        <li>Effective team management.</li>
                        <li>Effective management of cash flow.</li>
                        <li>
                          Effective management of the payroll system ensuring
                          all relevant payments are correct and paid on time.
                        </li>
                        <li>
                          Information system – Support and implementation.
                        </li>
                      </ul>
                    </CareerAccordion>
                  </div>

                  <div className="text-center px-4 py-8">
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Position Location:
                    </h5>
                    <p className="text-white mb-4 text-sm">
                      Durban, South Africa.
                    </p>
                    <h5 className="text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative">
                      Send Your CV To:
                    </h5>
                    <p className="text-white text-sm mb-4">
                      <a href="mailto:hr@aspropeaccess.com">
                        {" "}
                        hr@aspropeaccess.com{" "}
                      </a>
                    </p>
                    <div className="inline-block w-12 h-12 bg-white rounded-full shadow-lg relative">
                      <FaExclamation className="text-2xl text-brand-blue absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2" />
                    </div>
                    <p className="text-sm font-bold text-white">
                      Applications Close 21 September 2022
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
