import React from "react"
import { useState } from "react"
import { FaPlus, FaMinus } from "react-icons/fa"

export default function CareerAccordion({ title, children }) {
  const [open, setOpen] = useState(false)

  const toggleAccordion = () => {
    setOpen(!open)
  }

  return (
    <div className="career-accordion bg-brand-blue mb-4">
      <h5
        onClick={toggleAccordion}
        className="p-4 text-white font-semibold uppercase text-sm tracking-wide cursor-pointer relative"
      >
        {title}
        {!open ? (
          <FaPlus className="absolute right-4 top-1/2 transform -translate-y-1/2" />
        ) : (
          <FaMinus className="absolute right-4 top-1/2 transform -translate-y-1/2" />
        )}
      </h5>
      <div className={open ? "px-4 pb-4 text-white text-sm" : "hidden"}>
        {children}
      </div>
    </div>
  )
}
